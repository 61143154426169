import React from 'react';
import ColorController from '../../controllers/ColorController';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

const Table = ({ propToUse, ...props }) => {
  console.log(props.tableBody)
  return (
    <div>
      <div
        id='bordered-table'
        className='table-view '
        style={{
          borderCollapse: 'collapse',
          borderSpacing: '5px 15px',
          ...props.overrideTableStyles,
        }}>
        <div
          className='table-header'
          style={{
            backgroundColor: `${props.headerBGColor || '#F0F0F0'}`,
            color: `${props.headerColor || '#43425D'}`,
          }}>
          {props?.header?.map((item, idx) => (
            <div className={'th '} style={{ ...tableBorder }} key={idx}>
              <p>{item}</p>
            </div>
          ))}
        </div>

        <div className='table-body no-border'>
          {props?.tableBody.length > 0 ? (
            props?.tableBody?.map((item, idx) => (
              <div className='table-row no-border' key={idx}>
                {propToUse.map((name, index) => (
                  <div className='td' key={index} style={{ ...tableBorder }}>
                    {name.toLowerCase() === 'status' ? (
                      <div className='m-r-auto flex'>
                        <div
                          className='m-r-5'
                          style={{
                            width: '1.1rem',
                            backgroundColor: `${ColorController.getColor(item[name]) || 'red'}`,
                            borderRadius: '50%',
                            height: '1.1rem',
                          }}
                        />
                        <div className='my-auto'>{item[name] || 'saved'}</div>
                      </div>
                    ) : name.toLowerCase() === 'signature64' ? (
                    <div >
                      <img style={{width:'100px'}} src={`data:image/png;base64, ${item[name]}`} alt="Approver's Signature"/>
                    </div>
                    ) 
                     :(
                      item[name]
                    )}
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div className='td m-l-10'>No Record Yet</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
