const paths = {
  // Expense Endpoint
  'get-roles': 'ApprovalSetups/roles',
  'approval-limit': 'ApprovalSetups/approvallimit',
  approvers: 'ApprovalSetups/approvalsequence',
  'approver-sequence': 'ApprovalSetups/sequence',
  mdas: 'MonthlyResource/mda',
  'budget-periods': 'MonthlyResource/budgetperiod',
  'activate-period': 'MonthlyResource',
  'set-amount': 'MonthlyResource/setamount',
  "procurement-items" : "api/Expenses/procurementitems",
  'search-employee': 'api/Expenses/searchemployee',
  'search-vendors': 'api/Expenses/searchvendors',
  'budget-info': 'api/Expenses/budgetinfo',
  'get-currencies': 'api/Expenses/currency',
  'initiate-budget': 'api/Expenses/expense/initiate',
  "initiate" : "api/expenses/expense/initiate",
  'update-expense': 'api/Expenses/expense/update',
  'expense-tracking': 'api/Report/expense-tracking',
  'budget-item': 'api/Expenses/budgetitems',
  'saved-recurr-expense': 'api/Expenses/savedrecurrexpenses',
  'saved-capital-expense': 'api/Expenses/savedcapitalexpenses',
  'saved-capex-expense': 'api/Expenses/savedcapexexpenses',
  'validate-saved-expense': 'api/Expenses/expense/submitvalidation',
  "submit-capital":'api/Expenses/expense/submitvalidationforcapital',
  "submit-capex":'api/Expenses/expense/submitvalidationforcapex',
  'submit-expense': 'api/Expenses/expense/submit',
  'delete-expense': 'api/Expenses/expense/delete',
  'save-budget-item': 'api/Expenses/item/save',
  'search-endorser': 'api/Expenses/endorser/search',
  'save-endorser': 'api/Expenses/endorser/save',
  'save-supporting-doc': 'api/Expenses/supportingdoc/save',
  'get-supporting-doc': 'api/Expenses/savedsupportingdocuments',
  'delete-supporting-doc': 'api/Expenses/supportingdoc/delete',
  'added-expense-item': 'api/Expenses/saveditems',
  'edit-expense-item': 'api/Expenses/item/update',
  'saved-endorser': 'api/Expenses/savedendorser',
  'delete-endorser': 'api/Expenses/endorser/delete',
  'pending-expense': 'api/ExpenseApprovalDetails/pendingexpense',
  'treated-expense': 'api/ExpenseApprovalDetails/treatedexpense',
  'selected-expense': 'api/ExpenseApprovalDetails/selectedexpense',
  'action-on-expense': 'api/ExpenseApprovalDetails/approval_query',
  'get-query': 'api/ExpenseApprovalDetails/loadquery',
  'get-pendings': 'api/Query/pending/list',
  'cancel-pending-expense': 'api/Query/pending/cancel',
  'query-list': 'api/Query/query/list',
  'load-query': 'api/Query/query/load',
  'reply-query': 'api/Query/query/reply',
  'finance-list': 'api/Finance/finance/list',
  'finance-payment': 'api/Finance/finance/paid',
  'dashboard-list': 'api/Report/dashboard/list',
  'dashboard-card': 'api/Report/dashboard/count',
  'load-dropdowns': 'api/Report/loaddropdowns',
  'get-budget-item': 'api/Report/budgetitems',
  'search-invoice': 'api/Report/search/invoice',
  'search-initiator': 'api/Report/search/initiator',
  'search-approveby': 'api/Report/search/approvedby',
  'submit-report': 'api/Report/report/list',
  'dashboard-table': 'api/Report/dashboard/statuslist',
  'vendors-setup': 'api/vendorsSetup/vendors/list',
  'add-vendors': 'api/VendorsSetup/add_vendor',
  'update-vendors': 'api/VendorsSetup/update_vendor',
  'delete-vendors': 'api/VendorsSetup/vendors/delete',
  'budget-stat' : 'api/Report/budgetstats'
  

};

export default paths;
